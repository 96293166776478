import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const StatusField = ({
                          source, record = {}, labels = {
    'created': 'Создан',
    'in_queue': 'В очереди',
    'inProgressImport': 'Импорт В процессе',
    'errorImport': "Ошибка импорта",
    'finishedImport': "Импорт завершен",
    'sent': 'Отправлен',
    'toSend': 'В очереди на отправку',
    'InProgressSend': 'Отправляется',
    'errorSend': 'Ошибка отправки',
    'completed': 'Исполнен',
    'finished': 'Завершен',
    'getting_answer': 'Есть ответ',
    'in_queue_answer': 'В очереди на ответ',
    'in_progress_answer': 'Получение ответа',
    'error': 'Ошибка'
  }, colors = {
    'in_progress': 'warning',
    'in_queue': 'warning',
    'in_queue_answer': 'warning',
    'in_progress_answer': 'warning',
    'sent': 'success',
    'finished': 'success',
    'completed': 'success',
    'error': 'error'
  } }: {labels?: any, source?: any, record?: any, colors?: any}) =>
  <Typography variant={'body2'} color={colors[record[source]] || ''}>{labels[record[source]] || ''}</Typography>;

StatusField.propTypes = {
  label: PropTypes.string,
  addLabel: PropTypes.bool,
  record: PropTypes.object,
  source: PropTypes.string.isRequired,
};

export default StatusField;
