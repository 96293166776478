import React, {Children, isValidElement, cloneElement, Fragment} from 'react';
import {
  ReferenceInput,
  List,
  Datagrid,
  Edit,
  Show,
  SelectInput,
  SimpleShowLayout,
  BooleanInput,
  Filter,
  ReferenceField,
  TextInput,
  FunctionField,
  UrlField,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DateInput,
  required,
  ShowButton,
  TabbedShowLayout,
  Labeled,
  Pagination,

  TopToolbar, CreateButton, Button,
  BulkDeleteButton,
  Tab, sanitizeListRestProps, ReferenceManyField, RadioButtonGroupInput
} from 'react-admin';
import {Typography, makeStyles, Paper} from '@material-ui/core'
import classnames from 'classnames';
import {Box, Grid} from '@material-ui/core'

import ResendButton from "./components/ResendButton";
import ResendManyButton from "./components/ResendManyButton";

import format from 'date-fns/format'
import {ru} from 'date-fns/locale'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import StatusField from 'src/components/fields/StatusField'
import BooleanField from 'src/components/fields/BooleanField'
import RelatedList from 'src/components/list/RelatedList'

const RequestFilter = (props) => (
  <Filter {...props}>

    <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true}
                    source="requestImportId" reference="import" variant={'outlined'} fullWidth={true}
    >
      <SelectInput
        optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy hh:mm', {locale: ru})} - ${record.fileName}`}/>
    </ReferenceInput>
    <TextInput source="packNumPl" label={'SSCC паллеты'} alwaysOn={true} variant={'outlined'}/>

    <TextInput source="packNumBx" label={'SSCC коробок'} alwaysOn={true} variant={'outlined'}/>
    <TextInput source="packNum" alwaysOn={true} label={'№ тары'} variant={'outlined'}/>
    <TextInput source="prodNumber" alwaysOn={true} label={'№ на крышке'} variant={'outlined'}/>
    <TextInput source="prodName||$contL" label={'Наименование'} variant={'outlined'}/>
    <SelectInput emptyText={'Не применять'} allowEmpty={true} source="packType" label={'Упаковка'} variant={'outlined'}
                 choices={[
                   {id: '03', name: '03 - Мешок'},
                   {id: '04', name: '04 - Канистра'},
                   {id: '05', name: '05 - Посевная ед.'},
                   {id: '06', name: '06 - Флакон'},
                   {id: '07', name: '07 - Пакет (6,4 л бактерии для сои)'},
                   {id: '08', name: '08 - Пакет (6 кг = 10растворимых пакетов)'},
                   {id: '09', name: '09 - Евро-куб'},
                 ]}/>
    <TextInput source="gtin" label={'GTIN'} variant={'outlined'}/>

    <TextInput source="batchNumber" label={'№ партии'} variant={'outlined'}/>

  </Filter>
);


const RequestBulkActionButtons = props => (
  <Fragment>
    <BulkDeleteButton label="Удалить" {...props} />
  </Fragment>
);
const ListActions = ({
                       currentSort,
                       className,
                       resource,
                       filters,
                       displayedFilters,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       filterValues,
                       permanentFilter,
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       showFilter,
                       maxResults,
                       total,
                       showCreate,
                       ...rest
                     }: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {showCreate ? <CreateButton basePath={basePath}/> : null}
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}


  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

export const RequestList = (props) => (
  <List {...props} title="Заявки Контур" filters={<RequestFilter/>}
        sort={{field: 'id', order: 'DESC'}}
        pagination={<Pagination rowsPerPageOptions={[5, 10, 25, 50, 100, 200, 500]}></Pagination>}
        actions={<ListActions showCreate={true}/>}
        bulkActionButtons={<RequestBulkActionButtons/>} empty={<EmptyList title={'Заявки отсутствуют'}
                                                                          description={'Для того чтобы начать работать импортируйте или добавьте заявку'}
                                                                          buttonText={'Добавить заявку'}/>}>
    <Datagrid>
      <IdShowButtonField label={'Id'}/>
      <TextField source="packNumPl" label={'SSCC паллеты'}/>
      <ReferenceField label="ID импорта" source="requestImportId" reference="import" link={'show'}>
        <TextField source="id"/>
      </ReferenceField>
      <TextField source="packNumBx" label={'SSCC коробок'}/>
      <TextField source="packNum" label={'№ тары'}/>
      <TextField source="prodNumber" label={'№ на крышке'}/>
      <TextField source="prodName" label={'Наименование'}/>
      <TextField source="packTypeName" label={'Упаковка'}/>
      <TextField source="weight" label={'Обьем/Вес'}/>
      <TextField source="gtin" label={'GTIN'}/>
      <DateField source="prodDate" label={'Произведен'}/>
      <DateField source="expiredDate" label={'Годен до'}/>
      <TextField source="batchNumber" label={'№ партии'}/>

      <DateField source={'completedAt'} label={'Отправлен'} showTime/>
    </Datagrid>
  </List>
);

const TitleShow = ({record}: any) => {
  return (
    <span>
      Заявка № {record.id}
    </span>
  );
};
const SanitizedGrid = ({...props}) => {
  console.log("props.children", props.children)
  const newProps = {
    record: (props as any).record,
    resource: (props as any).resource,
    basePath: (props as any).basePath,
  };
  return (
    <Grid item={props.item} md={props.md} container={props.container}>
      {Children.map(props.children, field =>
        field && isValidElement(field) ? (
          ['Box', 'SanitizedGrid'].indexOf((field.type as any).name) > -1 ? cloneElement(field, {...props, ...(field.props as any)}) :
            <div
              key={(field.props as any).source}
              className={classnames(
                `ra-field ra-field-${(field.props as any).source}`,
                (field.props as any).className
              )}
            >
              {(field.props as any).addLabel ? (
                <Labeled
                  record={props.record}
                  resource={props.resource}
                  basePath={props.basePath}
                  label={(field.props as any).label}
                  source={(field.props as any).source}
                  disabled={false}
                >
                  {field}
                </Labeled>
              ) : typeof field.type === 'string' ? (
                field
              ) : (
                cloneElement(field, newProps)
              )}
            </div>
        ) : null
      )}
    </Grid>
  );
};

export const RequestShow = (props) => (
  <Show {...props} title={<TitleShow/>}>


    <TabbedShowLayout>
      <Tab label="Заявка">

        <Typography variant="subtitle1" gutterBottom>
          Информация о заявке
        </Typography>
        <SanitizedGrid container spacing={4}>
          <SanitizedGrid container={false} item md={4}>
            <TextField source="packNumPl" label={'SSCC паллеты'}/>
            <ReferenceField label="ID импорта" source="requestImportId" reference="import" link={'show'}>
              <TextField source="id"/>
            </ReferenceField>
            <TextField source="packNumBx" label={'SSCC коробок'}/>
            <TextField source="packNum" label={'№ тары'}/>
            <TextField source="prodNumber" label={'№ на крышке'}/>
            <TextField source="prodName" label={'Наименование'}/>
            <TextField source="packTypeName" label={'Упаковка'}/>
            <TextField source="weight" label={'Обьем/Вес'}/>
            <TextField source="gtin" label={'GTIN'}/>
            <DateField source="prodDate" label={'Дата производства'}/>
            <DateField source="expiredDate" label={'Дата окончания срока годности'}/>
            <TextField source="batchNumber" label={'№ партии'}/>

          </SanitizedGrid>
          <SanitizedGrid container={false} item md={4}>
            <TextField source="id" label={'ID заявки'}/>
            <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
              <TextField source="id"/>
            </ReferenceField>
          </SanitizedGrid>

        </SanitizedGrid>


      </Tab>


    </TabbedShowLayout>
  </Show>
);



const Form = ({permissions, ...rest}) => {
  const source = rest.source || '';
  return (<>
    <TextInput source="packNumPl" label={'SSCC паллеты'}  fullWidth={true} alwaysOn={true} variant={'outlined'} />
    <TextInput source="packNumBx" label={'SSCC коробок'} fullWidth={true} variant={'outlined'} validate={required()}/>
    <TextInput source="packNum" fullWidth={true} label={'№ тары'} variant={'outlined'} validate={required()}/>
    <TextInput source="prodNumber" fullWidth={true} label={'№ на крышке'} variant={'outlined'} validate={required()}/>
    <TextInput source="prodName" label={'Наименование'}  fullWidth={true} variant={'outlined'} validate={required()}/>
    <SelectInput emptyText={'Не применять'} fullWidth={true} validate={required()} source="packType" label={'Упаковка'} variant={'outlined'}
                 choices={[
                   {id: '03', name: '03 - Мешок'},
                   {id: '04', name: '04 - Канистра'},
                   {id: '05', name: '05 - Посевная ед.'},
                   {id: '06', name: '06 - Флакон'},
                   {id: '07', name: '07 - Пакет (6,4 л бактерии для сои)'},
                   {id: '08', name: '08 - Пакет (6 кг = 10растворимых пакетов)'},
                   {id: '09', name: '09 - Евро-куб'},
                 ]}/>
    <RadioButtonGroupInput emptyText={'Не применять'} fullWidth={true} validate={required()} source="weightUnit" label={'Ед. Измерения веса '} variant={'outlined'}
                           choices={[
                             {id: 'LTR', name: 'LTR'},
                             {id: 'KGE', name: 'KGE'},
                           ]}/>
    <TextInput source="weight" label={'Обьем/Вес'}  variant={'outlined'} fullWidth={true} validate={required()}/>
    <DateInput source={'prodDate'} label={'Дата производства'} variant={'outlined'} fullWidth={true} validate={required()}/>
    <DateInput source={'expiredDate'} label={'Дата окончания срока годности'} variant={'outlined'} fullWidth={true} validate={required()}/>
    <TextInput source="gtin" label={'GTIN'} fullWidth={true} variant={'outlined'} validate={required()}/>
    <TextInput source="batchNumber" label={'№ партии'} fullWidth={true} variant={'outlined'} validate={required()}/>

  </>)
}
Form.propTypes = {
}

Form.defaultProps = {

}

export const RequestEdit = (props) => (
  <Edit {...props} title={<TitleShow/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const RequestCreate = (props) => (
  <Create title="Создать заявку" {...props}>
    <SimpleForm redirect={'list'} defaultValue={{ published: true, publishedAt: new Date()}}>
      <Form {...props}/>
    </SimpleForm>
  </Create>
)