import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { CreateButton } from 'react-admin'

const RelatedEmptyList = ({ basePath, title = 'List empty', description, buttonText, to, hasCreate = true }: any) => (
  <Box textAlign="center" m={4}>
    <Typography variant="h4" paragraph>
      {title}
    </Typography>
    <Typography variant="body1">
      {description}
    </Typography>
    {hasCreate && <CreateButton label={buttonText}   basePath={basePath} to={to} />}
  </Box>
)
export default RelatedEmptyList
