import React, { FC, ReactElement, Component, Fragment, useState, useEffect, Children } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles,
  PropTypes as MuiPropTypes,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import { Link } from 'react-router-dom';
import { linkToRecord, Record, useDataProvider, useNotify } from 'ra-core';
import { ReferenceInput, SelectInput, BooleanInput, useRefresh } from 'react-admin';
import { connect } from "react-redux";
import { FormWithRedirect, } from 'ra-core';
import DownloadIcon from '@material-ui/icons/GetApp';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import {

  useCreateController,
} from 'ra-core';
import {
  fetchEnd,
  fetchStart,
  required,
  showNotification,
  Button,
  FormInput,
  SaveButton,
  CREATE,
  REDUX_FORM_NAME,
} from "react-admin";

import IconContentAdd from "@material-ui/icons/Add";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import IconCancel from "@material-ui/icons/Cancel";
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'flex-end'
  },
  gridItem: {
    width: '50%'
  }
}))

interface Props {
  basePath,
  resource,
  show,
  total,
  filter?: any,
  sort?: any,
  handleClose,
}


const SyncModal: FC<Props> = ({
                                                  basePath,
                                                  resource,
                                                  show,
  total,
  sort,
  filter,
                                              handleClose,
                                                  ...rest
                                                }) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [saving, setSaving] = useState(false);
  const history = useHistory();
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const refresh = useRefresh();
  useEffect(() => {
    setShowDialog(show);
  }, [show]);

  const handleCloseClick = () => {
    handleClose();
  };


  const handleSubmit = (values) => {

    setSaving(true);
    dataProvider
      .create(`kontur/box/sync`, {
        data: {}
      })
      .then(
        ({ data }) => {
          setSaving(false);
          refresh();
          handleClose()
          notify('Ящики синхронизированы', 'info');
        }
      )
      .catch(error => {
        console.error(error);
        setSaving(false);
        notify('ra.notification.http_error', 'warning');
      });

  }


  return (
    <Dialog
      fullWidth
      open={showDialog}
      onClose={handleCloseClick}
      aria-label="Синхронизация ящиков с Контур"
    >
      <DialogTitle>Синхронизация ящиков с Контур</DialogTitle>
      <FormWithRedirect save={handleSubmit} version={1000} saving={false} redirect={''} onSubmit={null} render={ ({handleSubmit}) => (<Fragment>
        <DialogContent>
          {Children.map(
            [
            ],
            input =>
              input && (
                <FormInput
                  basePath={basePath}
                  input={input}
                  resource={resource}
                />
              )
          )}

            <Box  p={2}><Typography>Вы уверены что хотите синхронизировать ящики с Контур</Typography>
            </Box>
        </DialogContent>
        <DialogActions>
          <SaveButton
            handleSubmitWithRedirect={
              handleSubmit
            }
            label={'Синхронизировать'}
            icon={<RefreshIcon/>}
            saving={saving}

          />
          <Button label="ra.action.cancel" onClick={handleClose}>
            <IconCancel />
          </Button>
        </DialogActions>
        </Fragment>)}/>
    </Dialog>
);
}


SyncModal.propTypes = {
  basePath: PropTypes.string,
  show: PropTypes.bool,
  total: PropTypes.number,
  handleClose: PropTypes.func,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  filter: PropTypes.object,
};

export default SyncModal;
