import React, {Children, isValidElement, cloneElement, Fragment} from 'react';
import {
  ReferenceInput,
  List,
  Datagrid,
  Edit,
  Show,
  SelectInput,
  SimpleShowLayout,
  BooleanInput,
  Filter,
  ReferenceField,
  TextInput,
  FunctionField,
  UrlField,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DateInput,
  required,
  ShowButton,
  TabbedShowLayout,
  Labeled,
  Pagination,
  TopToolbar, CreateButton, Button,
  Tab, sanitizeListRestProps, ReferenceManyField, RadioButtonGroupInput
} from 'react-admin';

import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import BooleanField from 'src/components/fields/BooleanField'
import SyncButton from 'src/resources/Box/components/SyncButton'

const BoxFilter = (props) => (
  <Filter {...props}>
    <TextInput source="konturId" label={'Kontur ID'} alwaysOn={true} variant={'outlined'}/>
    <TextInput source="konturPartyId" label={'Party ID'} alwaysOn={true} variant={'outlined'}/>
    <TextInput source="konturGln" alwaysOn={true} label={'GLN'} variant={'outlined'}/>
  </Filter>
);

const ListActions = ({
                       currentSort,
                       className,
                       resource,
                       filters,
                       displayedFilters,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       filterValues,
                       permanentFilter,
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       showFilter,
                       maxResults,
                       total,
                       showCreate,
                       ...rest
                     }: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {showCreate ? <CreateButton basePath={basePath}/> : null}
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}

    <SyncButton {...rest}/>
  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

export const BoxList = (props) => (
  <List {...props} title="Ящики Контур" filters={<BoxFilter/>}
        sort={{field: 'id', order: 'DESC'}}
        actions={<ListActions showCreate={true}/>} empty={<EmptyList title={'Ящики отсутствуют'}
                                                                          description={'Для того чтобы начать работать добавьте ящик'}
                                                                          buttonText={'Добавить ящик'}
                                                                     customButton={<SyncButton {...props}/>}/>}>
    <Datagrid>
      <IdShowButtonField label={'Id'}/>
      <TextField source="konturId" label={'Kontur ID'}/>
      <TextField source="konturPartyId" label={'Party ID'}/>
      <TextField source="konturGln" label={'GLN'}/>
      <BooleanField source="isTest" label={'Тест'}/>
      <BooleanField source="isMain" label={'Главный'}/>
      <DateField source={'createdAt'} label={'Создан'} showTime/>
    </Datagrid>
  </List>
);

const TitleShow = ({record}: any) => {
  return (
    <span>
      Ящик № {record.id}
    </span>
  );
};


const Form = ({permissions, ...rest}) => {
  return (<>
    <TextInput source="konturId" label={'Kontur ID ящика'}  fullWidth={true} alwaysOn={true} variant={'outlined'} validate={required()} />
    <TextInput source="konturPartyId" label={'Party ID'} fullWidth={true} variant={'outlined'} validate={required()}/>
    <TextInput source="konturGln" fullWidth={true} label={'GLN'} variant={'outlined'} validate={required()}/>
    <BooleanInput source="isTest" label={'Тестовый'}  variant={'outlined'} fullWidth={true}/>
    <BooleanInput source="isMain" label={'Главный'}  variant={'outlined'} fullWidth={true}/>
  </>)
}
Form.propTypes = {
}

Form.defaultProps = {

}

export const BoxEdit = (props) => (
  <Edit {...props} title={<TitleShow/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const BoxCreate = (props) => (
  <Create title="Создать ящик" {...props}>
    <SimpleForm redirect={'list'} defaultValue={{ published: true, publishedAt: new Date()}}>
      <Form {...props}/>
    </SimpleForm>
  </Create>
)