import React from 'react';
import {
  Show,
  SimpleShowLayout,
  ReferenceInput,
  List,
  Datagrid,
  Filter,
  SelectInput,
  FunctionField,
  UrlField,
  Edit,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DateInput,
  TextInput,
  FileInput,
  FileField,
  ArrayInput,
  SimpleFormIterator,
  RadioButtonGroupInput,
  required, TabbedShowLayout, Tab, ReferenceManyField, TopToolbar, BooleanInput, ReferenceField, useRefresh
} from 'react-admin';
import UpdateButton from "./components/UpdateButton";

import Button from '@material-ui/core/Button';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import ListActions from 'src/components/list/ListActions'
import StatusField from 'src/components/fields/StatusField'
import RelatedList from 'src/components/list/RelatedList'
import SendButton from 'src/resources/Import/components/SendButton'
import useInterval from 'react-useinterval';
const ImportFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" alwaysOn={true}    variant={'outlined'} label={'id'} />
    <SelectInput source="status" alwaysOn={true}   allowEmpty={true}   variant={'outlined'} label={'Статус'} choices={[
      { id: 'created', name: 'Импорт В очереди' },
      { id: 'inProgressImport', name: 'Импорт В процессе' },
      { id: 'finishedImport', name: 'Импорт Заверешен' },
      { id: 'errorImport', name: 'Импорт Ошибка' },
      { id: 'toSend', name: 'Импорт Ошибка' },
      { id: 'InProgressSend', name: 'Импорт Ошибка' },
      { id: 'toSend', name: 'Отправка в очереди' },
      { id: 'InProgressSend', name: 'Отправка В процессе' },
      { id: 'errorSend', name: 'Отправка Ошибка' },
      { id: 'sent', name: 'Отправлен' },
    ]} />
    <TextInput source="fileName||$contL" alwaysOn={true}    variant={'outlined'} label={'Файл'} />
    <TextInput source="docName||$contL" alwaysOn={true}    variant={'outlined'} label={'№ документа'} />
    <TextInput source="recipientGln" label={'GLN покупателя'}  variant={'outlined'}/>


  </Filter>
);
const LinkToRelatedRequests = ({ record }: any) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/request',
        search: stringify({
          page: 1,
          perPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({ requestImportId: record.id }),
        }),
      }}
    >
      Заявки
    </Button>
  ) : null;
};


const LinkToRelatedEvents = ({ record }: any) => {
  return record ? (
    <Button
      color="primary"
      component={Link}
      to={{
        pathname: '/kontur/event',
        search: stringify({
          page: 1,
          perPage: 25,
          sort: 'id',
          order: 'DESC',
          filter: JSON.stringify({ requestImportId: record.id }),
        }),
      }}
    >
      События
    </Button>
  ) : null;
};
export const ImportList = (props) =>{
  const refresh = useRefresh();

  useInterval(() => {
   refresh();
  }, 7000);
  return (
  <List {...props} title="Импорты" filters={<ImportFilter />} actions={<ListActions/>}
        sort={{ field: 'id', order: 'DESC' }}
        bulkActionButtons={false} empty={<EmptyList title={'Импорты отсутсвуют'} description={'Импортируйте заявки нажав кнопку «Импортировать»'} buttonText={'Импортировать'}/>}>
    <Datagrid>
      <IdShowButtonField source="id" />
      <TextField source="fileName" label={'Файл'}/>
      <TextField source="docName" label={'№ документа'}/>
      <TextField source="docDate" label={'Дата документа'}/>
      <ReferenceField label="Событие" source="lastEventId" reference="kontur/event" link={'show'}>
        <TextField source="type"/>
      </ReferenceField>
      <StatusField source="status" label={'Статус'} />
      <TextField source="totalRows" label={'Всего'}/>
      <TextField source="importedRows" label={'Импортировано'}/>
      <TextField source="errorRows" label={'Ошибок'}/>
      <LinkToRelatedEvents/>
      <LinkToRelatedRequests/>
      <SendButton label={'Отправить'} />

      <DateField source={'createdAt'} label={'Создан'} showTime/>
    </Datagrid>
  </List>
);
}

const TitleShow = ({ record } : any) => {
  return (
    <span>
      Импорт заявок № {record.id}
    </span>
  );
};

const ImportShowActions = (props) => (
  <TopToolbar>
    <EditButton label={'Обновить'} {...props} record={props.data}/>
    <SendButton label={'Отправить'} {...props} record={props.data}/>
  </TopToolbar>
);


export const ImportShow = (props) => (
  <Show {...props} title={<TitleShow/>} actions={<ImportShowActions/>}>
    <TabbedShowLayout>
      <Tab label="Данные импорта">
        <LinkToRelatedEvents/>
        <LinkToRelatedRequests/>
        <TextField source="id" />
        <TextField source="docName" label={'№ документа'}/>
        <TextField source="docDate" label={'Дата документа'}/>
        <TextField source="docDate" label={'Дата документа'}/>
        <TextField source="recipientGln" label={'GLN покупателя'}/>
        <StatusField source="status" label={'Статус'} />
        <TextField source="totalRows" label={'Всего'}/>
        <TextField source="importedRows" label={'Импортировано'}/>
        <TextField source="errorRows" label={'Ошибок'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>
      <Tab label="Контур">
        <TextField source="konturBoxId" label={'BoxId'}/>
        <TextField source="konturMessageId" label={'MessageId'}/>
        <TextField source="konturDocumentCirculationId" label={'DocumentCirculationId'}/>
        <TextField source="error" label={'Ошибка'}/>
      </Tab>
      <Tab label="Ошибки импорта" path="errors">
        <ReferenceManyField reference="import-error" target="requestImportId" addLabel={false}>
          <RelatedList rowClick={'show'}
                       hasCreate={false}
                       emptyTitle={'Ошибки отсуствуют'}>
            <TextField source="row" label={'№ Строки'}/>
            <TextField source="error" label={'Ошибка'}/>
          </RelatedList>
        </ReferenceManyField>
      </Tab>
    </TabbedShowLayout>

  </Show>
);


const Form = ({permissions, ...rest}) => {
  return (<>
    <ReferenceInput label="Ящик"  emptyText={'Не применять'}
                    source="boxId" reference="kontur/box" variant={'outlined'} fullWidth={true} validate={required()}
    >
      <SelectInput
        optionText={(record) => `${record.id} - ID: ${record.konturId}, GLN: ${record.konturGln}, ${record.isTest ? 'Test' : ''} ${record.isMain ? 'Главный' : ''}`}/>
    </ReferenceInput>
    <TextInput source="docName" label={'Номер документа'}  variant={'outlined'} fullWidth={true} validate={required()}/>
    <DateInput source="docDate" label={'Дата документа'}  variant={'outlined'} fullWidth={true} validate={required()}/>
    <TextInput source="recipientGln" label={'GLN покупателя'}  variant={'outlined'} fullWidth={true} validate={required()}/>
    <FileInput source="file" multiple={false} label="Файл импорта" accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" placeholder={<p>Перетащите файл или нажмите</p>} validate={required()}>
      <FileField source="src" title="title" />
    </FileInput>
  </>)
}
Form.propTypes = {
}

Form.defaultProps = {

}

export const ImportEdit = (props) => (
  <Edit {...props} title={<TitleShow/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <Form {...props}/>
    </SimpleForm>
  </Edit>
)

export const ImportCreate = (props) => (
  <Create title="Импорт заявок" {...props}>
    <SimpleForm redirect={'list'} defaultValue={{ published: true, publishedAt: new Date()}}>
      <Form {...props}/>
    </SimpleForm>
  </Create>
)
