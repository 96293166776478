import * as React from 'react';
import { useCallback, useContext, useState, FunctionComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import DownloadIcon from '@material-ui/icons/GetApp';
import RefreshIcon from '@material-ui/icons/Refresh';
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  ExporterContext,
  Exporter,
} from 'ra-core';
import { ButtonProps } from '@material-ui/core/Button';
import { Button } from 'react-admin'
import SyncModal from "./SyncModal";

const SyncButton: FunctionComponent<SyncButtonProps> = ({
                                                              sort,
                                                              filter = defaultFilter,
                                                              maxResults = 1000,
                                                              resource,
                                                              onClick,
                                                              basePath,
                                                              total,
                                                              label = 'ra.action.export',
                                                              icon = defaultIcon,
                                                              exporter: customExporter,
                                                              ...rest
                                                            }) => {
  const exporterFromContext = useContext(ExporterContext);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [showDialog, setShowDialog] = useState(false);


  const handleClose = () => {
    setShowDialog(false);
  }

  const handleClick = useCallback(
    event => {

      console.log("propsExport", rest)
      setShowDialog(true);

    },
    [
      dataProvider,
      exporter,
      filter,
      maxResults,
      notify,
      onClick,
      resource,
      sort,
    ]
  );


  return (
    <Fragment>
      <Button
        onClick={handleClick}
        label={'Синхронизироать с Контур'}
      >
        {icon}
      </Button>
      <SyncModal sort={sort} filter={filter} total={total} show={showDialog} basePath={basePath} resource={resource} handleClose={handleClose}/>
    </Fragment>
  );
};

const defaultIcon = <RefreshIcon/>;
const defaultFilter = {};

const sanitizeRestProps = ({
                             basePath,
                             ...rest
                           }: Omit<SyncButtonProps,
  'sort' | 'filter' | 'maxResults' | 'resource' | 'label' | 'exporter'>) => rest;

interface Props {
  basePath?: string;
  exporter?: Exporter;
  filter?: any;
  icon?: JSX.Element;
  label?: string;
  maxResults?: number;
  onClick?: (e: Event) => void;
  resource?: string;
  sort?: any;
  total: number,
}

export type SyncButtonProps = Props & ButtonProps;

SyncButton.propTypes = {
  basePath: PropTypes.string,
  exporter: PropTypes.func,
  filter: PropTypes.object,
  label: PropTypes.string,
  maxResults: PropTypes.number,
  total: PropTypes.number,
  resource: PropTypes.string.isRequired,
  sort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.string,
  }),
  icon: PropTypes.element,
};

export default SyncButton;
