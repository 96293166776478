import { createBrowserHistory } from 'history'

let browserHistory = null

export const history = () => {
  if (!browserHistory) {
    browserHistory = createBrowserHistory()

    const basePush = browserHistory.push.bind(browserHistory)

    browserHistory.push = (path, state) => {
      basePush(path, { ...state, previous: browserHistory.location.pathname })
    }
  }

  return browserHistory
}

export const navigateTo = (url) => {
  history().push(url)
}
