import * as React from 'react';
import { FC, ReactElement } from 'react';
import PropTypes from 'prop-types';
import shouldUpdate from 'recompose/shouldUpdate';
import ImageEye from '@material-ui/icons/RemoveRedEye';
import { Link } from 'react-router-dom';
import { linkToRecord, Record } from 'ra-core';
import {Button} from 'react-admin'
const BooleanField: FC<IdShowButtonFieldProps> = ({
                                           record,
                                           source,
                                           ...rest
                                         }) => (
  <>
    {record[source] ? ' Есть' : 'Нет'}
  </>
);

interface Props {
  record?: Record;
  source: string
}

export type IdShowButtonFieldProps = Props;

BooleanField.propTypes = {
  record: PropTypes.any,
};

const enhance = shouldUpdate(
  (props: Props, nextProps: Props) =>
    (props.record &&
      nextProps.record &&
      props.record.id !== nextProps.record.id) ||
    (props.record == null && nextProps.record != null)
);

export default enhance(BooleanField);
