import { stringify } from "querystring";
import React, {useEffect} from 'react'
import { Admin, Resource, DataProvider } from 'react-admin'
import Layout from "src/common/layout/Layout";
import { AuthProvider } from "src/common/providers/AuthProvider";
import { dataProvider } from "src/common/providers/dataProvider";
// @ts-ignore
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import PublishIcon from '@material-ui/icons/Publish';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import customSagas from './sagas'
import './index.css'

import LinkIcon from '@material-ui/icons/Link';

import { theme } from 'src/style'
import { history } from './history'
import {UserCreate, UserEdit, UserList} from 'src/resources/users'
import {RequestCreate, RequestEdit, RequestList, RequestShow} from 'src/resources/Request'
import {ImportCreate, ImportEdit, ImportList, ImportShow} from 'src/resources/Import'
import {BoxCreate, BoxEdit, BoxList} from 'src/resources/Box'
import {EventList, EventShow} from 'src/resources/Event'
const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {

  return (
    <Admin
      theme={theme}
      history={history()}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      customSagas={customSagas}
      layout={Layout}
    >
      <Resource
        name="request"
        list={RequestList}
        show={RequestShow}
        create={RequestCreate}
        edit={RequestEdit}
        options={{ label: 'Заявки' }}
      />
      <Resource
        name="kontur/box"
        list={BoxList}
        create={BoxCreate}
        edit={BoxEdit}
        options={{ label: 'Ящики' }}
      />
      <Resource
        name="kontur/event"
        list={EventList}
        show={EventShow}
        options={{ label: 'События' }}
      />
      <Resource
        name="import-error"
      />
      <Resource
        name="import"
        list={ImportList}
        create={ImportCreate}
        edit={ImportEdit}
        show={ImportShow}
        options={{ label: 'Импорты' }}
        icon={PublishIcon}
      />
      <Resource
        name="user"
        list={UserList}
        create={UserCreate}
        edit={UserEdit}
        icon={EmojiPeopleIcon}
        options={{ label: 'Пользователи' }}

      />
    </Admin>
  )
}

export default App
