import React, { createElement, useCallback, useState } from 'react'
import { useSelector } from 'react-redux'
import { useMediaQuery } from '@material-ui/core'
import { MenuItemLink, getResources } from 'react-admin'
import DefaultIcon from '@material-ui/icons/ViewList'
import HomeIcon from '@material-ui/icons/Home'
import Accessibility from '@material-ui/icons/Accessibility'
import SubMenu from "src/common/layout/SubMenu";

import { AppState } from 'src/types'

const Menu = ({ onMenuClick, dense, logout }) => {
  const isXSmall = useMediaQuery((theme: any) => theme.breakpoints.down('xs'))
  const open = useSelector((state: AppState) => state.admin.ui.sidebarOpen)
  const resources: any = useSelector(getResources)
  const [openedItems, setOpenedItems] = useState([])

  const handleToggle = useCallback((itemName) => {
    const isOpened = openedItems.find((item) => item === itemName);
    if (isOpened) {
      setOpenedItems(openedItems.filter((item) => item !== itemName));
    } else {
      setOpenedItems([...openedItems, itemName]);
    }

  }, [openedItems])

  const renderMenuLink = useCallback((resource) => {

    if(resource.options && resource.options.hidden){
      return null;
    }
    const items = resource.options && resource.options.items ? resource.options.items : [];
    return items.length > 0 ? <SubMenu handleToggle={handleToggle}
                                       isOpen={openedItems.find((item) => item === resource.name) || false}
                                       sidebarIsOpen={open}
                                       key={resource.name}
                                       keyName={resource.name}
                                       dense={dense}
                                       icon={resource.icon ? <resource.icon/> : <DefaultIcon/>}
                                       name={(resource.options && resource.options.label) || resource.name}>
        {items ? items.map((resource) => renderMenuLink(resource)) : null}
      </SubMenu>
      : <MenuItemLink
        sidebarIsOpen={open}
        leftIcon={resource.icon ? <resource.icon/> : <DefaultIcon/>}
        primaryText={(resource.options && resource.options.label) || resource.name}
        to={ resource.to ? resource.to : `/${resource.name}` }
        onClick={onMenuClick}
        dense={dense}
      />

  }, [openedItems])

  return (
    <div>
      {resources.filter(item => item.hasList).map(resource => renderMenuLink(resource))}
      {isXSmall && logout}
    </div>
  )
}

export default Menu
