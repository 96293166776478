import { call, put, select } from 'redux-saga/effects'

import request from './request'
import { IRequestData, IResponse } from 'src/types'
import * as Cookies from 'js-cookie'

function* requestGen(options: IRequestData | string): Generator<any, IResponse> {
  const accessToken = Cookies.get('auth-token') as string
  const optionsObj = typeof options === 'string' ? { url: options } : options
  const token = accessToken
  const res: any = yield call(request, { ...optionsObj, token })

  if (res.err && res.err.Code === 'TOKEN_EXPIRED') {
  //  yield put(logout())
  }

  return res
}

export default requestGen
