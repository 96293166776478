import React from 'react'
import {
  required,
  List,
  Datagrid,
  Filter,
  SelectInput,
  Edit,
  Create,
  SimpleForm,
  TextField,
  TextInput, ReferenceInput,

} from 'react-admin'


const UserFiler = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} alwaysOn={true}  variant={'outlined'} />
    <TextInput source="login||$contL&name||$contL" label={'Поиск'} alwaysOn={true} resettable={true} variant={'outlined'} />
    <SelectInput
      source="role"
      label={'Роль'}
      allowEmpty={true}
      variant={'outlined'}
      choices={[
        { id: 'manager', name: 'Менеджер' },
        { id: 'admin', name: 'Админ' },
        { id: 'SuperAdmin', name: 'Супер админ' },
      ]}
    />

  </Filter>
)

export const UserList = (props) => (
  <List
    {...props}
    title="Пользователи"
    filters={<UserFiler/>}

    sort={{ field: 'login', order: 'ASC' }}
    bulkActionButtons={false}
   >

    <Datagrid rowClick={'edit'}>

      <TextField source="id" label={'ID'}/>
      <TextField source="roleName" label={'Роль'}/>
      <TextField source="login" label={'Логин'}/>
      <TextField source="name" label={'Имя'}/>

    </Datagrid>
  </List>
)

const TitleEdit = ({ record }: any) => {
  return (
    <span>
      Пользователь: {record.login}
    </span>
  )
}

export const UserEdit = (props) => (
  <Edit {...props} title={<TitleEdit/>} undoable={false}>
    <SimpleForm redirect={'list'}>
      <TextInput source="name" label={'ФИО'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="login" label={'Логин'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <SelectInput
        source="role"
        variant={'outlined'}
        label={'Роль'}
        fullWidth={true}
        choices={[
          { id: 'manager', name: 'Менеджер' },
          { id: 'admin', name: 'Админ' },
          { id: 'SuperAdmin', name: 'Супер админ' },
        ]}
      />
      <TextInput source="setPassword" label={'Пароль'} variant={'outlined'} fullWidth={true} />

    </SimpleForm>
  </Edit>
)

export const UserCreate = (props) => (
  <Create title="Создать пользователя" {...props}>
    <SimpleForm redirect={'list'}>
      <TextInput source="name" label={'ФИО'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <TextInput source="login" label={'Логин'} variant={'outlined'} fullWidth={true} validate={required()}/>
      <SelectInput
        source="role"
        variant={'outlined'}
        label={'Роль'}
        fullWidth={true}
        choices={[
          { id: 'manager', name: 'Менеджер' },
          { id: 'admin', name: 'Админ' },
          { id: 'super_admin', name: 'Супер админ' },
        ]}
      />
      <TextInput source="setPassword" type={'password'} label={'Пароль'} variant={'outlined'} fullWidth={true} validate={required()}/>
    </SimpleForm>
  </Create>
)
