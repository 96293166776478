import React, {Children, isValidElement, cloneElement, Fragment} from 'react';
import {
  ReferenceInput,
  List,
  Datagrid,
  Edit,
  Show,
  SelectInput,
  SimpleShowLayout,
  BooleanInput,
  Filter,
  ReferenceField,
  TextInput,
  FunctionField,
  UrlField,
  Create,
  SimpleForm,
  DateField,
  TextField,
  EditButton,
  DateInput,
  required,
  ShowButton,
  TabbedShowLayout,
  Labeled,
  Pagination,
  TopToolbar, CreateButton, Button,
  Tab, sanitizeListRestProps, ReferenceManyField, RadioButtonGroupInput, useRefresh
} from 'react-admin';

import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import BooleanField from 'src/components/fields/BooleanField'
import SyncButton from 'src/resources/Event/components/SyncButton'
import format from 'date-fns/format'
import {ru} from 'date-fns/locale'
import {Typography} from '@material-ui/core'
import StatusField from 'src/components/fields/StatusField'
import JsonField from 'src/components/fields/JsonField'
import useInterval from 'react-useinterval'

const EventFilter = (props) => (
  <Filter {...props}>
    <SelectInput alwaysOn={true} emptyText={'Не применять'} allowEmpty={true} source="type" label={'Тип'}
                 variant={'outlined'}
                 choices={[
                   {id: 'Unknown', name:'Unknown'},
                   {id: 'NewOutboxMessage', name:'NewOutboxMessage'},
                   {id: 'NewInboxMessage', name:'NewInboxMessage'},
                   {id: 'RecognizeMessage', name:'RecognizeMessage'},
                   {id: 'MessageDelivered', name:'MessageDelivered'},
                   {id: 'MessageUndelivered', name:'MessageUndelivered'},
                   {id: 'MessageReadByPartner', name:'MessageReadByPartner'},
                   {id: 'MessageCheckingOk', name:'MessageCheckingOk'},
                   {id: 'MessageCheckingFail', name:'MessageCheckingFail'},
                   {id: 'DraftOfDocumentPackagePostedIntoDiadoc', name:'DraftOfDocumentPackagePostedIntoDiadoc'},
                   {id: 'DraftOfDocumentPackageSignedByMe', name:'DraftOfDocumentPackageSignedByMe'},
                   {id: 'DraftOfDocumentPackageDeletedFromDiadoc', name:'DraftOfDocumentPackageDeletedFromDiadoc'},
                   {id: 'DraftOfDocumentPackageSignedBySender', name:'DraftOfDocumentPackageSignedBySender'},
                   {id: 'ReceivedDiadocRoamingError', name:'ReceivedDiadocRoamingError'},
                   {id: 'DiadocRevocationAccepted', name:'DiadocRevocationAccepted'},
                   {id: 'DiadocRevocationAcceptedForBuyer', name:'DiadocRevocationAcceptedForBuyer'},
                   {id: 'DocumentPackageSignedByRecipientOk', name:'DocumentPackageSignedByRecipientOk'},
                   {id: 'DocumentPackageSignedByMeOk', name:'DocumentPackageSignedByMeOk'},
                   {id: 'DocumentPackageSignedByRecipientFail', name:'DocumentPackageSignedByRecipientFail'},
                   {id: 'DocumentPackageSignedByMeFail', name:'DocumentPackageSignedByMeFail'},
                   {id: 'AmendmentRequested', name:'AmendmentRequested'},
                   {id: 'ProcessingTimesReport', name:'ProcessingTimesReport'},
                   {id: 'DiadocDocumentDelivered', name:'DiadocDocumentDelivered'},
                 ]}/>


    <ReferenceInput label="Импорт" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true}
                    source="requestImportId" reference="import" variant={'outlined'} fullWidth={true}
    >
      <SelectInput
        optionText={(record) => `${record.id} от ${format(new Date(record.createdAt), 'dd MMMM yyyy hh:mm', {locale: ru})} - ${record.fileName}`}/>
    </ReferenceInput>
    <ReferenceInput label="Ящик" alwaysOn={true} emptyText={'Не применять'} allowEmpty={true}
                    source="boxId" reference="kontur/box" variant={'outlined'} fullWidth={true}
    >
      <SelectInput
        optionText={(record) => `${record.id} - ID: ${record.konturId}, GLN: ${record.konturGln}, ${record.isTest ? 'Test' : ''} ${record.isMain ? 'Главный' : ''}`}/>
    </ReferenceInput>
    <TextInput source="eventId" label={'ID события (Контур)'} alwaysOn={true} variant={'outlined'}/>
    <TextInput source="partyId" label={'Party ID'} variant={'outlined'}/>

  </Filter>
);

const ListActions = ({
                       currentSort,
                       className,
                       resource,
                       filters,
                       displayedFilters,
                       exporter, // you can hide ExportButton if exporter = (null || false)
                       filterValues,
                       permanentFilter,
                       hasCreate, // you can hide CreateButton if hasCreate = false
                       basePath,
                       selectedIds,
                       onUnselectItems,
                       showFilter,
                       maxResults,
                       total,
                       showCreate,
                       ...rest
                     }: any) => (
  <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
    {filters && cloneElement(filters, {
      resource,
      showFilter,
      displayedFilters,
      filterValues,
      context: 'button',
    })}


  </TopToolbar>
);

ListActions.defaultProps = {
  selectedIds: [],
  onUnselectItems: () => null,
  showCreate: true
};

export const EventList = (props) => {
  const refresh = useRefresh();

  useInterval(() => {
    refresh();
  }, 7000);
  return (
    <List {...props} title="События Контур" filters={<EventFilter/>}
          sort={{field: 'id', order: 'DESC'}}
          actions={<ListActions showCreate={false}/>} empty={<EmptyList title={'События отсутствуют'}
                                                                        showCreate={false}/>}>
      <Datagrid>
        <IdShowButtonField label={'Id'}/>
        <TextField source="type" label={'Тип'}/>
        <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
          <TextField source="docName"/>
        </ReferenceField>
        <ReferenceField label="Ящик" source="boxId" reference="kontur/box" link={'show'}>
          <TextField source="konturId"/>
        </ReferenceField>
        <TextField source="eventId" label={'ID События контур'}/>
        <DateField source={'eventTime'} label={'Время'} showTime/>
      </Datagrid>
    </List>
  );
}

const TitleShow = ({record}: any) => {
  return (
    <span>
      Событие № {record.id}
    </span>
  );
};


export const EventShow = (props) => (
  <Show {...props} title={<TitleShow/>}>


    <TabbedShowLayout>
      <Tab label="Событие">
        <TextField source="id" label={'ID'}/>
        <TextField source="type" label={'Тип'}/>
        <ReferenceField label="Импорт" source="requestImportId" reference="import" link={'show'}>
          <TextField source="docName"/>
        </ReferenceField>
        <ReferenceField label="Ящик" source="boxId" reference="kontur/box" link={'show'}>
          <TextField source="konturId"/>
        </ReferenceField>
        <TextField source="eventId" label={'ID События контур'}/>
        <TextField source="partyId" label={'Party ID'}/>
        <DateField source={'eventTime'} label={'Время'} showTime/>
        <JsonField source={'eventContent'} label={'Содержимое события'}/>

      </Tab>


    </TabbedShowLayout>
  </Show>
);