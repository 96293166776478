import * as materialColors from '@material-ui/core/colors'
import { createMuiTheme } from '@material-ui/core/styles'

export const colors = {
  primary: materialColors.blue[800],
  secondary: materialColors.blue[700],
}

export const chartColors = [
  materialColors.blue[400],
  materialColors.red[400],
  materialColors.green[400],
  materialColors.purple[400],
  materialColors.indigo[400],
  materialColors.amber[400],
  materialColors.pink[400],
  materialColors.orange[400],
  materialColors.blueGrey[400],
  materialColors.brown[400],
  materialColors.cyan[400],
  materialColors.deepOrange[400],
  materialColors.deepPurple[400],
  materialColors.grey[400],
  materialColors.lightBlue[400],
  materialColors.lightGreen[400],
  materialColors.yellow[400],
  materialColors.teal[400],
]

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    type: 'light',
  },
  overrides: {
    MuiTextField: {},
  },
})
